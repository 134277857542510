const replaceHomePageCrumb = (homePageCrumb, crumbs) =>
  homePageCrumb
    ? {
        breadCrumb: crumbs.map((crumb, index) => {
          if (!index) {
            crumb.crumbLabel = homePageCrumb;
          }

          return crumb;
        }),
      }
    : undefined;

export default replaceHomePageCrumb;
