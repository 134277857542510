import React, { FC } from 'react';
import { Layout } from 'layout';
import classNames from 'classnames';

import { useUpdateOTLds } from '@phx-husky/use-update-ot-lds';

import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import SignUpBanner from 'components/SignUpBanner';
import replaceHomePageCrumb from 'utils/replaceHomePageCrumb';

import { LdsPageProps } from './models';

import './ldsPage.scss';
import './ldsPageOverride.scss';

const LdsPage: FC<LdsPageProps> = ({
  pageContext: {
    body,
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoExternalHreflangs, ogImage },
    title,
    slug,
    breadcrumb: { crumbs },
    homePageCrumb,
    versionId,
    platform,
    businessId,
  },
}) => {
  const modifierClass = `${slug}-${lang}`;

  const breadcrumbs = replaceHomePageCrumb(homePageCrumb, crumbs);

  const { updatedLdsContent, isCheckingLdsVersion } = useUpdateOTLds({
    baseUrl: process.env.GATSBY_LDS_BASE_URL as string,
    businessId,
    versionId,
    platform,
  });

  return (
    <Layout breadcrumbs={breadcrumbs} langProps={{ urls, lang }}>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
        }}
      />
      {!isCheckingLdsVersion && (
        <section
          className={classNames('lds-page', {
            [`lds-page--${modifierClass}`]: modifierClass,
          })}
        >
          {title ? <h1 className="lds-page__title">{title}</h1> : null}
          <div dangerouslySetInnerHTML={{ __html: updatedLdsContent ?? body }} />
        </section>
      )}
      <SignUpBanner {...{ lang }} />
    </Layout>
  );
};

export default LdsPage;
